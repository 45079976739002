<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Header } from "./components/workspace";
import { useViewStore } from "@/store/view";
import { useTrackStore } from "@/store/modules/track";
import type { MainInstance } from "element-plus";
import Assistance from "@/components/ai-assistant/assistance.vue";

const route = useRoute();
const viewStore = useViewStore();
const { track } = useTrackStore();
const pageContainerRef = ref<MainInstance | null>(null);
const globalDomain = inject("globalDomain");

const key = computed(() => {
  return route.path;
});

const firstViewReport = () => {
  const path = window.location.pathname.split("/")[1];
  const noReport = ["premium", "payment", "login", "signup", ""];
  if (!noReport.includes(path)) {
    track("boolvideo_page_view");
  }
};

onMounted(() => {
  viewStore.pageContainer = pageContainerRef.value!.$el as HTMLElement;
  firstViewReport();
});
</script>

<template>
  <div class="common-layout h-screen bg-globalBg">
    <el-container class="h-full">
      <el-header>
        <Header></Header>
      </el-header>
      <el-main ref="pageContainerRef">
        <Assistance v-if="globalDomain === 1" :loading="false"/>
        <router-view v-slot="{ Component }" :key="key">
          <transition name="el-fade-in" mode="out-in">
            <component :is="Component" :key="key" />
          </transition>
        </router-view>
      </el-main>
    </el-container>
  </div>
</template>

<style lang="scss" scoped>
.common-layout {
  :deep(.el-main) {
    padding: 0;
  }
}

.el-header {
  border-bottom: 1px solid #e8e9ec;
  z-index: 2;
}
</style>
