<template>
  <div class="side-info-container">
    <div class="px-3">
      <template v-if="isSubscribe">
        <el-descriptions>
          <el-descriptions-item>
            <template #label>
              <span class="text-tipColor">Plan: </span>
            </template>
            <span class="text-tipColor">{{ planName }}</span>
            <span v-if="isSubscribe" style="margin-left: 8px">
              <el-tooltip placement="top">
                <template #content>
                  <ul class="quota-container">
                    <template v-if="globalDomain === 1">
                      <li class="list-disc">
                        {{
                          "Video tools credit" +
                          (isPlural(getVideoToolNum()) ? "s" : "") +
                          ": " +
                          getVideoToolNum().toLocaleString() + " sec"
                        }}
                      </li>
                      <li class="list-disc">
                        {{
            "AI assistant credit" +
            (isPlural(getAIAssistantCredit()) ? "s" : "") +
            ": " +
            getAIAssistantCredit().toLocaleString()
          }}
                      </li>
                      <li class="list-disc">
                        {{
                          "Voiceover credit" +
                          (isPlural(getVoiceoverNum()) ? "s" : "") +
                          ": " +
                          getVoiceoverNum().toLocaleString() + " sec"
                        }}
                      </li>
                      <li class="list-disc">
                        {{
            "Video export credit" +
            (isPlural(getExportCredit()) ? "s" : "") +
            ": " +
            getExportCredit().toLocaleString()
          }}
                      </li>
                    </template>
                    <template v-else-if="globalDomain === 2">
                      <li class="list-disc">
                        {{
                          "Video Content Extraction: " +
                          getContentExtraction()
                        }}
                      </li>
                      <li class="list-disc">
                        {{
                          "Video Content Understanding: " +
                          getContentUnderstanding()
                        }}
                      </li>
                      <li class="list-disc">
                        {{
                          "Voiceover credit" +
                          (isPlural(getSimilarVoiceover()) ? "s" : "") +
                          ": " +
                          getSimilarVoiceover().toLocaleString() + " sec"
                        }}
                      </li>
                    </template>
                  </ul>
                </template>
                <svg-icon name="icon_info_filled" :style="{ width: '18px', height: '18px', display: 'inline' }" />
              </el-tooltip>
            </span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions>
          <el-descriptions-item v-if="globalDomain === 1">
            <template #label>
              <span class="text-tipColor">Cloud: </span>
            </template>
            <span class="text-tipColor">{{ formatSpaceSize(spaceInfo.usedSize) }} /
              {{ formatSpaceSize(spaceInfo.totalSize) }}</span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions>
          <el-descriptions-item>
            <template #label>
              <span class="text-tipColor">Expire: </span>
            </template>
            <span class="text-tipColor">{{
        dayjs(expire).format("MM/DD/YYYY")
      }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <template v-if="!isSubscribe">
        <el-descriptions>
          <el-descriptions-item>
            <template #label>
              <span class="text-tipColor">Plan: </span>
            </template>
            <span class="text-tipColor">{{ planName }}</span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions>
          <el-descriptions-item>
            <template #label>
              <span class="text-tipColor">{{
                "Voiceover: "
                }}</span>
            </template>
            <span class="text-tipColor">{{ globalDomain === 1 ? getVoiceoverNum() : getSimilarVoiceover() + " sec" }}</span>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions v-if="globalDomain === 1">
          <el-descriptions-item>
            <template #label>
              <span class="text-tipColor">{{
                "Export: "
                }}</span>
            </template>
            <span class="text-tipColor leading-22">
              {{ getExportCredit() }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </div>

    <div class="upgrade-btn" @click="handleUpgrade">
      {{ currentUpgrade}}
    </div>
    <subscribe-dialog :visible="subscribeModalVisible" @close="handleCloseSubscribeModal" @refresh="handleRefresh" :showIntroduction="showIntroduction" />
  </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { ref, computed, watchEffect } from "vue";
import { useSubscriptionInfo, useSpaceStore} from "@/store/modules/user";
import { icon_arrow } from "@/assets/icons/workspace/index";
import { openPremiumInTab } from "@/utils/premium";
import { DataUnit } from "@/utils/type";
import { toFixed } from "@/utils/common";
import SubscribeDialog from './subscribeDialog/index.vue'

const spaceInfo = useSpaceStore();
const subscriptionInfo = useSubscriptionInfo();
const planName = ref("");
const expire = ref(0);
const isSubscribe = computed(() => planName.value !== "Free Plan");
const currentUpgrade = ref('Upgrade to standard')
const showIntroduction = ref(false);
const globalDomain = inject("globalDomain");

globalDomain === 1 && spaceInfo.refresh();

const subscribeModalVisible = ref(false)
const handleUpgrade = () => {
  subscribeModalVisible.value = true
}

const handleRefresh = () => {
  subscriptionInfo.refresh();
}

const handleCloseSubscribeModal = () => {
  showIntroduction.value = false
  subscribeModalVisible.value = false
}

watchEffect(() => {
  switch (subscriptionInfo.$state.packageCode) {
    case 400201: 
      planName.value = "Standard";
      currentUpgrade.value = 'Upgrade to pro';
      break;
    case 400301:
      planName.value = "Pro";
      currentUpgrade.value = 'Manage my subscription';
      break;
    case 300201:
      // Month 
      planName.value = "Standard";
      currentUpgrade.value = 'Upgrade to pro';
      break;
    case 300202:
      // year 
      planName.value = "Annual standard";
      currentUpgrade.value = 'Upgrade to annual pro';
      break;
    case 300301:
      // month
      planName.value = "Pro";
      currentUpgrade.value = 'Upgrade to annual standard';
      break;
    case 300302:
      planName.value = "Annual pro";
      currentUpgrade.value = 'Manage my subscription';
      break;
    case 300401:
    case 300402:
    case 300404:
      planName.value = "Starter";
      currentUpgrade.value = 'Upgrade to standard';
      break;
    case 300205:
      planName.value = "RH Standard";
      currentUpgrade.value = 'Manage my subscription';
      break;
    case 300304:
      planName.value = "LTD Pro";
      currentUpgrade.value = 'Manage my subscription';
      break;
    case 300305:
      planName.value = "RH Pro"
      currentUpgrade.value = 'Manage my subscription';
      break;
    case 300204:
      planName.value = "LTD Standard";
      currentUpgrade.value = 'Manage my subscription';
      break;
    case 300405:
      planName.value = "RH Starter"
      currentUpgrade.value = 'Manage my subscription';
      break;
    default:
      planName.value = "Free Plan";
      currentUpgrade.value = 'Upgrade to standard';
      break;
  }
});

watchEffect(() => {
  expire.value = subscriptionInfo.$state.dueTime;
});

const isPlural = (value: string | number) => {
  if (value === "Unlimited") {
    return true;
  }
  if (typeof value === "number" && value > 1) {
    return true;
  }
  return false;
}

const getContentExtraction = () => {
  let count = 0;
  const freeCount = subscriptionInfo.getUserResource(
    "SIMILAR_VIDEO_CONTENT_EXTRACTION_N",
  );
  if (freeCount) {
    count += freeCount.num;
  }
  const premiumCount = subscriptionInfo.getUserResource(
    "SIMILAR_VIDEO_CONTENT_EXTRACTION_30",
  );
  if (premiumCount) {
    count += premiumCount.num;
  }
  const dataUnit = new DataUnit(count, "KB").toUnit();
  return toFixed(dataUnit.value, 1) + dataUnit.unit;
};

const getContentUnderstanding = () => {
  let count = 0;
  const freeCount = subscriptionInfo.getUserResource(
    "SIMILAR_VIDEO_CONTENT_UNDERSTANDING_N",
  );
  if (freeCount) {
    count += freeCount.num;
  }
  const premiumCount = subscriptionInfo.getUserResource(
    "SIMILAR_VIDEO_CONTENT_UNDERSTANDING_30",
  );
  if (premiumCount) {
    count += premiumCount.num;
  }
  const unit = count > 60 ? "min" : "sec" 
  const value = count > 60 ? Math.trunc(count / 60) : count
  return value + " " + unit + (isPlural(value)? "s" : "");
};

const getSimilarVoiceover = () => {
  let count = 0;
  const freeCount = subscriptionInfo.getUserResource(
    "SIMILAR_VIDEO_VOICEOVER_N",
  );
  if (freeCount) {
    count += freeCount.num;
  }
  const premiumCount = subscriptionInfo.getUserResource(
    "SIMILAR_VIDEO_VOICEOVER_30",
  );
  if (premiumCount) {
    count += premiumCount.num;
  }
  return count;
}

const getVideoToolNum = () => {
  const videoToolData = subscriptionInfo.getUserResource(
    "BOOLVIDEO_TOOLS_VIDEO",
  );
  if (!videoToolData) {
    return 0;
  }
  const videoToolNum =
    videoToolData?.num === -1 ? "Unlimited" : videoToolData.num;
  return videoToolNum;
};

const getVoiceoverNum = () => {
  let credit = 0;
  const freeVoiceoverData = subscriptionInfo.getUserResource(
    "BOOLVIDEO_VOICEOVER_TIME_N",
  );
  if (freeVoiceoverData) {
    credit += freeVoiceoverData.num;
  }
  const paidVoiceoverData = subscriptionInfo.getUserResource(
    "BOOLVIDEO_VOICEOVER_TIME_30",
  );
  if (paidVoiceoverData) {
    credit += paidVoiceoverData.num;
  }
  return credit;
};

const getExportCredit = () => {
  const exportResouceData = subscriptionInfo.getUserResource(
    "BOOLVIDEO_DOWNLOAD_30",
  );
  if (!exportResouceData) {
    return 0;
  }
  const exportCredit =
    exportResouceData?.num === -1 ? "Unlimited" : exportResouceData.num;
  return exportCredit;
};

const getAIAssistantCredit = () => {
  let credit = 0;
  const freeAIAssistantData = subscriptionInfo.getUserResource(
    "BOOLVIDEO_AI_ASSISTANT_NUM_N",
  );
  if (freeAIAssistantData) {
    credit += freeAIAssistantData.num;
  }
  const paidAIAssistantData = subscriptionInfo.getUserResource(
    "BOOLVIDEO_AI_ASSISTANT_NUM_30",
  );
  if (paidAIAssistantData) {
    credit += paidAIAssistantData.num;
  }
  return credit;
}

// 跳转Premium 订阅
const handleToPremium = () => {
  openPremiumInTab();
};

const formatSpaceSize = (size: number) => {
  const dataUnit = new DataUnit(size);
  return toFixed(dataUnit.value, 1) + dataUnit.unit;
};
</script>

<style scoped lang="scss">
.quota-container {
  margin: 20px 24px 24px;
  font-size: 14px;
}

.upgrade-btn {
  width: 100%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #875EFF;
  color: #6741FF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter-variant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    background: #F8F5FF;
  }
}
</style>
