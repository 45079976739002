<template>
  <el-dialog 
    v-model="showPortrait"
    modal-class="user-portrait-modal"
    fullscreen
    destroy-on-close
    :show-close="false"
    :append-to-body="true"
  >
    <template #header>
      <Header />
    </template>
    <div 
      class="question-container">
      <div class="title-container">
        <div class="title" v-if="page === selectList.length - 1">
          All Done
          <div class="emoji">👌</div>
        </div>
        <div class="title" v-else>
          Welcome To {{projectName}}! 
          <div class="emoji">👏</div>
        </div>
        <p class="subtitle">
          {{ 
            page === selectList.length - 1 ?
            `Thanks for your time! Enjoy creating with ${Boolvideo}!`  : 
            "Introduce yourself to improve your overall experience"
          }}
        </p>
      </div>
      <div 
        class="item-container" 
      >
        <p>{{ currentQuestion.question + (currentQuestion.multiple ? " ( Multiple choice )" : "") }}</p>
          <div class="grid-container">
            <template v-for="(info, index) in currentQuestion.values">
              <QuestionItem 
                v-if="selectList.length > 0"
                :info="info"
                :index="index"
                @select="handleSelect"
              />
            </template>
          </div>
      </div>
      <Footer 
        :page="page" 
        :pageList="selectList"
        @submit="submit"
        @updatePage="setPage"
      />
    </div>

  </el-dialog>
</template>

<script setup>
import { computed } from 'vue'
import Footer from "./footer.vue";
import QuestionItem from "./item.vue";
import { quesitionList } from "./data";
import { Header } from "@/layout/components/workspace";
import { queryUserPortrait, postUserPortrait } from "@/api/userInfo";

const globalDomain = inject('globalDomain') 
const showPortrait = ref(false);
const selectList = ref([]);
const page = ref(0);

const projectName = computed(() => {
  return globalDomain == 2 ? 'Similarvideo' : 'Boolvideo'
})

onMounted(async () => {
  const { data, success } = await queryUserPortrait();
  if (success && !data) {
    showPortrait.value = true;
    initList();
    watch(
      page,
      () => {
        selectList.value[page.value].visited = true;
      },
      {
        immediate: true,
      },
    );
  }
});

const currentQuestion = computed(() => {
  return selectList.value[page.value];
});

const initList = () => {
  selectList.value = quesitionList.map((info) => {
    const { name, question, values, editable, multiple } = info;
    const newValues = values.map((item) => {
      return { value: item, selected: false, editable: false};
    });
    if (info.editable) {
      newValues.push({ value: "", selected: false, editable: true });
    }
    return {
      name,
      question,
      values: newValues,
      multiple,
      editable,
      visited: false,
    };
  });
};

const handleSelect = (item, itemIndex, forceTrue = false) => {
  const currentPageInfo = selectList.value[page.value];
  if(currentPageInfo.multiple){
    item.selected = !item.selected;
  }
  else {    
    currentPageInfo.values.forEach((info, index) => {
      if(index === itemIndex){
        if (forceTrue) {
          info.selected = true;
        }
        else{
          info.selected = !info.selected;
        }
        return;
      }
      info.selected = false;
    });
  }
};

const setPage = (value) => {
  if (value < selectList.value.length && value >= 0) {
    page.value = value;
  }
};

const parseData = () => {
  return selectList.value.map(info => {
    const { name, question, values } = info;
    const newValues = values
    .filter(item => item.selected)
    .map(item => {
      if (item.selected){
        return item.value;
      }
    });
    return {
      name,
      question,
      values: newValues,
    }
  })
};

const submit = async () => {
  const payload = {
    question: JSON.stringify(parseData()),
  };
  await postUserPortrait(payload);
  showPortrait.value = false;
};
</script>
<style>
.user-portrait-modal .el-dialog {
  border-radius: 0;
}

.user-portrait-modal .el-dialog__header{
  padding: 0 20px;
  margin: 0;
}

.user-portrait-modal .el-dialog__body {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0;
  border-top: 1px solid #e8e9ec;
}
</style>
<style lang="scss" scoped>
.question-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-container p + p{
  margin-top: 11px;
}

.title {
  position: relative;
  color: #060606;
  font-size: 38px;
  font-weight: 700;
  line-height: 46px;

  & .emoji {
    width: 46px;
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    position: absolute;
    top: 50%;
    right: -46px;
    transform: translate(0, -50%);
  }
}

.subtitle {
  color: #646A73;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.item-container {
  height: 364px;
}

.item-container > p{
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px; 
  text-align: center;
  margin-bottom: 36px;
}

.grid-container {
  display: grid;
  gap: 28px;
  grid-template-columns: repeat(3, 298px);
  color: #060606;
}
</style>