<script setup lang="jsx">
import icon_head_sculpture from '@/assets/icons/common/icon_head_sculpture.svg';
import { useUserStore, useSubscriptionInfo } from '@/store/modules/user';
import SubscribeDetailDialog from './workspace/subscribeDialog/premium/components/modal/subscribing.vue';
import { getUserName, getAvatar } from '@/utils/auth';

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  packageCode: Number,
});

const { packageType } = storeToRefs(useSubscriptionInfo())
const { updateSubscriptionState } = useSubscriptionInfo();
const userStore = useUserStore();
const router = useRouter();

const clientWidth = ref(0);
const subscribeDetDialogVisible = ref(false);

const handleDetailClose = () => {
  subscribeDetDialogVisible.value = false;
};

onMounted(() => {
  measureClient();
  updateSubscriptionState();
  window.onresize = () => {
    measureClient();
  };
});

const measureClient = () => {
  const width = document.documentElement.clientWidth;
  clientWidth.value = width;
};

const handleResetPassword = () => {
  router.push({ path: '/resetPwd', query: { step: 1, type: 'reset' } });
};

const handleLogout = async () => {
  await userStore.logout();
};

const handleViewPlan = () => {
  subscribeDetDialogVisible.value = true;
};

const handleManageBill = () => {
  const { VITE_STRIPE_BILL } = import.meta.env;
  window.open(VITE_STRIPE_BILL, '_blank');
};
</script>
<template>
  <el-dropdown
    popper-class="avatar-dropdown"
    placement="bottom-end"
    trigger="click"
    :teleported="false"
    :popper-options="{
      modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
    }"
  >
    <img class="avatar" :src="getAvatar()" alt="User Avatar" />
    <template #dropdown>
      <el-dropdown-menu class="avatar-menu">
        <el-dropdown-item class="avatar-menu-item">
          <div class="font-semibold username">{{ getUserName() }}</div>
        </el-dropdown-item>
        <el-dropdown-item
          v-for="(option, i) in options"
          :key="i"
          @click="option.handler"
        >
          <div>{{ option.label }}</div>
        </el-dropdown-item>
        <el-dropdown-item @click="handleViewPlan">
          <div>Manage My Plan</div>
        </el-dropdown-item>
        <el-dropdown-item @click="handleResetPassword">
          <div>Reset Password</div>
        </el-dropdown-item>
        <el-dropdown-item
          @click="handleManageBill"
          v-if="packageType === 'STRIPE_SUB'"
        >
          Manage Your Billing
        </el-dropdown-item>
        <el-dropdown-item @click="handleLogout">
          <div>Log Out</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <SubscribeDetailDialog
    v-if="subscribeDetDialogVisible"
    @close="handleDetailClose"
  />
</template>
<style lang="scss" scoped>
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
}

.popper-class {
  background-color: red;
}

.avatar-menu {
  min-width: 150px;
  padding-block: 8px;

  & > :deep(li) {
    color: #1f2329;
    padding-inline: 12px;

    & > svg {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }

    & > span {
      font-size: 14px;
    }
  }
}

.username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style lang="scss">
.el-dialog {
  border-radius: 8px;
}
.avatar-dropdown.el-popper {
  z-index: 99999;
}

.avatar-dropdown {
  border: 1px solid var(--card-border-color);
  box-shadow: 0px 4px 8px 0px rgba(31, 35, 41, 0.1);
  transform: none !important;
  z-index: 999999;

  & > .el-popper__arrow {
    display: none;
  }
}
.avatar-dropdown {
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #060606 !important;
    background-color: #ebedef !important;
  }

  .el-dropdown-menu__item:not(.is-disabled):focus {
    color: #060606 !important;
    background-color: #ebedef !important;
  }
}

.avatar-menu-item.el-dropdown-menu__item:not(.is-disabled):focus {
  background: #fff !important;
  color: rgb(31, 35, 41) !important;
}

.avatar-menu-item.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #fff !important;
  color: rgb(31, 35, 41) !important;
}
</style>
