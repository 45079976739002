import { ref } from 'vue';
import { EventListener, toFixed } from './common';
import type { Ref } from 'vue';

/**
 * 创建一个使用于js的引用类型
 * ```js
 * const obj = { name: 'xxx' };
 * const ref = createRef(obj, 'name');
 * console.log(ref == 'xxx'); // true
 * obj.name = 'abc';
 * console.log('_' + ref); // _abc
 * ```
 */
// acceptImageType acceptVideoType acceptAudioType acceptAllType
export const IMAGETYPE =
  '.jpg,.jpeg,.png,.gif,.bmp,.webp,.heif,.avif,.heic,.tiff,.tif,.ico,.jfif';
export const VIDEOTYPE =
  '.mp4,.avi,.mov,.mkv,.flv,.wmv,.webm,.m4v,.ts,.rmvb,.mpg,.mpeg';
export const AUDIOTYPE = '.mp3,.wav,.aac,.flac,.ogg,.m4a,.wma,.dsf,.dff';
export const IMAGEANDVIDEOTYPE =
  '.jpg,.jpeg,.png,.gif,.bmp,.webp,.heif,.avif,.heic,.tiff,.tif,.ico,.jfif,.mp4,.avi,.mov,.mkv,.flv,.wmv,.webm,.m4v,.ts,.rmvb,.mpg,.mpeg';

export function createRef<T extends object, K extends keyof T>(
  base: T,
  key: K
) {
  return new (Proxy as ObjectProxyConstructor<T, T[K]>)(base, {
    defineProperty(_, prop, descriptor) {
      Object.defineProperty(base[key], prop, descriptor);
      return true;
    },
    deleteProperty(_, prop) {
      if (Object.isExtensible(base[key])) {
        return Reflect.deleteProperty(base[key] as object, prop);
      }

      return false;
    },
    getPrototypeOf(_) {
      return Object.getPrototypeOf(base[key]);
    },
    setPrototypeOf(_, prototype) {
      Object.setPrototypeOf(base[key], prototype);
      return true;
    },
    get(_, prop) {
      const value = (base[key] as any)[prop];

      if (typeof value === 'function') {
        return value.bind(base[key]);
      }

      return value;
    },
    set(_, prop, value) {
      (base[key] as any)[prop] = value;
      return true;
    },
    has(_, prop) {
      return prop in (base[key] as any);
    },
    ownKeys(_) {
      return Object.keys(base[key] as any);
    },
  });
}

/** 创建一个动态引用类型 */
export function createDynamicRef<T>(getValue: () => T): T {
  const ref = { value: null as T };

  Object.defineProperty(ref, 'value', {
    get() {
      return getValue();
    },
  });

  return createRef(ref, 'value');
}

/** 创建一个适用于vnode的引用类型 */
export function vref<T, E extends T extends Ref<infer R> ? R : T>(value: T) {
  return new Proxy<E & Ref<E>>(isRef<E>(value) ? value : (ref(value) as any), {
    get(target, prop) {
      if (prop === Symbol.toPrimitive) {
        return () => target.value;
      }

      return target[prop as keyof typeof target];
    },
  });
}

/** 创建一个默认值代理 */
export function createDefaultValueProxy<
  T extends object,
  E extends object = ExtractPartial<T>,
>(target: T, defaultValue: E) {
  return new (Proxy as ObjectProxyConstructor<T, Required<T>>)(target, {
    get(target, prop) {
      return target[prop as keyof T] ?? defaultValue[prop as keyof E];
    },
    has(target, prop) {
      return prop in target || prop in defaultValue;
    },
    ownKeys: () => {
      return [
        ...new Set([...Object.keys(target), ...Object.keys(defaultValue)]),
      ];
    },
  }) as Required<T>;
}

export function createValueProxy<T extends object>(
  target: T,
  replacedValues: Partial<T>
) {
  return new Proxy<T>(target, {
    get(target, prop) {
      return prop in replacedValues
        ? replacedValues[prop as keyof T]
        : target[prop as keyof T];
    },
  });
}

/** 创建一个动态代理 */
export function createDynamicProxy<
  T extends object,
  R extends Record<string, (value: T) => any>,
>(value: T, target: R) {
  return new Proxy<{
    [K in keyof R]: ReturnType<R[K]>;
  }>({} as any, {
    get(_, key) {
      if (key in target) {
        return target[key as keyof R](value);
      }

      return undefined;
    },
  });
}

export function createFieldsProxy<
  T extends object,
  F extends (keyof T)[] | Set<keyof T>,
>(target: T, fields: F) {
  const fieldSet = new Set(fields);

  return new Proxy(target as T, {
    get: (_, prop: any) =>
      fieldSet.has(prop) ? target[prop as keyof T] : undefined,
    set: (_, prop: any, value) => {
      const flag = fieldSet.has(prop);

      flag && Reflect.set(target, prop, value);
      return flag;
    },
    has: (_, prop: any) => fieldSet.has(prop),
    ownKeys: () => {
      return [...fieldSet] as any;
    },
    deleteProperty: (_, prop: any) => {
      const flag = fieldSet.has(prop) && Reflect.deleteProperty(target, prop);

      flag && fieldSet.delete(prop);
      return flag;
    },
  });
}

/** 创建一个分组代理 */
export function createGroupProxy<
  T extends object,
  G extends Record<string, (keyof T)[]>,
>(taregt: T, groups: G) {
  type GroupProxy = typeof result & {
    rest: {
      [K in Exclude<keyof T, G[keyof G][number]>]: T[K];
    };
  };

  const restProps = new Set(Object.keys(taregt) as ValueOf<G>);
  const result = {} as {
    [K in keyof G]: {
      [P in G[K][number]]: T[P];
    };
  };

  for (const key of Object.keys(groups) as (keyof G)[]) {
    if (key === 'rest') continue;
    const group = groups[key];

    for (const prop of group) {
      if (!restProps.has(prop)) {
        // console.warn(`The property "${prop.toString()}" of [createGroupProxy] does not exist in 'target' or already exists in another group`);
      }

      restProps.delete(prop);
    }

    result[key] = createFieldsProxy(taregt, group);
  }

  return {
    ...result,
    rest: createFieldsProxy(taregt, restProps),
  } as GroupProxy;
}

type MutexEvent = { meta: object; ref: Ref<boolean> };
type MutexEventListeners = {
  mutexchange: ((event: MutexEvent) => void)[];
};

/** 创建一个互斥空间，空间内同一时刻最多只有一个状态为真 */
export class MutexSpace extends EventListener<MutexEventListeners> {
  _focus: Ref<boolean> | null = null;

  constructor() {
    super({
      mutexchange: [],
    });
  }

  ref(meta: object = {}) {
    const valueRef = ref(false);
    const wrapper = new Proxy(valueRef, {
      set: (target, prop, value) => {
        if (prop === 'value' && value !== target.value) {
          target.value = value;

          if (this._focus !== null) {
            this._focus.value = false;
          }

          this._focus = value ? valueRef : null;
          this.emit('mutexchange', { meta, ref: wrapper });
        } else {
          Reflect.set(target, prop, value);
        }

        return true;
      },
    });

    return wrapper;
  }
}

export class DataUnit {
  static B = 1;
  static KB = 2 ** 10;
  static MB = 2 ** 20;
  static GB = 2 ** 30;
  static TB = 2 ** 40;
  static PB = 2 ** 50;
  static EB = 2 ** 60;
  static ZB = 2 ** 70;
  static BASE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'] as const;

  value: number;
  unit: (typeof DataUnit.BASE_UNITS)[number];

  constructor(value: number, unit?: (typeof DataUnit.BASE_UNITS)[number]) {
    this.value = value;
    this.unit = unit || 'B';

    if (unit === undefined) {
      const target = this.toUnit();
      this.value = target.value;
      this.unit = target.unit;
    }
  }

  toUnit(unit?: (typeof DataUnit.BASE_UNITS)[number]): DataUnit {
    if (unit === undefined) {
      let unit: typeof this.unit = 'B';
      let value = this.toUnit('B').value;

      for (unit of DataUnit.BASE_UNITS) {
        if (value < 1024) break;

        value /= 1024;
      }

      return new DataUnit(value, unit);
    } else {
      return new DataUnit(
        this.value / (DataUnit[unit] / DataUnit[this.unit]),
        unit
      );
    }
  }

  toString() {
    return this.value + this.unit;
  }
}

export interface AcceptType {
  types: string[];
  maxSize: number;
}

export class FileType {
  acceptTypes: AcceptType[];
  constructor(types: string | AcceptType | AcceptType[]) {
    if (typeof types === 'string') {
      this.acceptTypes = [
        {
          types: types === '' ? ['*'] : types.split(','),
          maxSize: Infinity,
        },
      ];
    } else if (Array.isArray(types)) {
      this.acceptTypes = types;
    } else {
      this.acceptTypes = [types];
    }

    for (const { types } of this.acceptTypes) {
      for (const [i, type] of types.entries()) {
        if (type !== '*' && type[0] !== '.') {
          throw new Error('File type must start with a period');
        }

        types[i] = type.toLowerCase();
      }
    }
  }

  check(file: File) {
    const filename = file.name.toLocaleLowerCase();

    for (const { type, maxSize } of this.types()) {
      if (type === '*' || filename.endsWith(type)) {
        if (file.size <= maxSize) {
          return { passed: true, msg: '' };
        } else {
          const dataUnit = new DataUnit(maxSize);
          return {
            passed: false,
            msg: `File size should be less than ${toFixed(dataUnit.value)}${
              dataUnit.unit
            }`,
          };
        }
      }
    }

    return { passed: false, msg: 'Only files in the following formats are supported. Please check and try again.' };
  }

  get formats() {
    return ([] as string[])
      .concat(...this.acceptTypes.map(({ types }) => types))
      .join(',');
  }

  *types() {
    for (const { types, maxSize } of this.acceptTypes) {
      for (const type of types) {
        yield { type, maxSize };
      }
    }
  }
}

export class TimedTask {
  private _timer: number = -1;
  task: () => Promise<void> | void;
  delay: number;
  times: number = 1;

  constructor(
    task: () => Promise<void> | void,
    delay = 16.667,
    times = Infinity
  ) {
    this.delay = delay;
    this.times = times;
    this.task = task;
  }

  get isRunning() {
    return this._timer !== -1;
  }

  run() {
    if (!this.isRunning) {
      this.start();
    }
  }

  start() {
    this.stop();

    if (this.times > 0) {
      this._timer = window.setTimeout(this._handler.bind(this), this.delay);
    }
  }

  stop() {
    if (this.isRunning) {
      window.clearTimeout(this._timer);
      this._timer = -1;
    }
  }

  private async _handler() {
    this.times--;
    await this.task();

    if (this.times > 0 && this.isRunning) {
      this._timer = window.setTimeout(this._handler.bind(this), this.delay);
    }
  }
}

export function useTimedTask(...args: ConstructorParameters<typeof TimedTask>) {
  const timer = new TimedTask(...args);
  onBeforeUnmount(() => timer.stop());
  return timer;
}
