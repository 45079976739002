<script setup lang="ts">
import SideInfo from './sideinfo.vue';
import { routes } from '@/router/routes';
import { openPremiumInTab } from '@/utils/premium';
import { useTrackStore } from '@/store/modules/track';
import DiscordTip from './discordTip.vue';
import { useShowCommunity } from './util.js';
import { setItem } from '@/utils/storage';
import { render } from 'vue';
import { createRef, isOnTop, useScopeId } from '@/utils';
import { createVNode } from 'vue';
import { useSubscriptionInfo } from '@/store/modules/user';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import SubscribeDialog from './subscribeDialog/index.vue';
import type { RouteRecordRaw } from 'vue-router';

const globalDomain = inject('globalDomain');
dayjs.extend(duration);
const scopeId = useScopeId();
const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { collectData, track } = useTrackStore();
const router = useRouter();
const { path } = useRoute();
const currentPath = ref(path);
const isShowDiscordTip = ref(false);
const tipMountedRef = ref(null as unknown as HTMLElement);

const subscribeModalVisible = ref(false);
const showIntroduction = ref(false);
const updateCancelCountdown = ref(0);
const cancelTimer = ref(null);
const cancelOnTop = ref(false);
const cancelGuideVisible = computed(() => {
  const { cancelTime, cancelSubAtPeriodEndBoolean } = subscriptionState.value;
  if (!cancelSubAtPeriodEndBoolean) return false;
  const now = dayjs();
  const cancel = dayjs(cancelTime);
  const diff = now.diff(cancel, 'day');

  return diff < 7;
});
const cancelCountDown = computed(() => {
  updateCancelCountdown.value;
  const { cancelTime } = subscriptionState.value;
  if (!cancelTime) return '';
  const now = dayjs();
  const expire = dayjs(cancelTime).add(7, 'day');
  const diff = expire.diff(now);

  const hours = dayjs.duration(diff).hours().toString().padStart(2, '0');
  const minutes = dayjs.duration(diff).minutes().toString().padStart(2, '0');
  const days = dayjs.duration(diff).days();
  const dayString = days > 1 ? 'days' : 'day';
  return `${days} ${dayString} ${hours}:${minutes}`;
});

watch(
  cancelGuideVisible,
  (value) => {
    if (value) {
      cancelTimer.value = setInterval(() => {
        updateCancelCountdown.value++;
        if (cancelOnTop.value) return;
        cancelOnTop.value = isOnTop(tipMountedRef.value);
      }, 1000);
    }
  },
  {
    once: true,
  }
);

const handleUpgrade = () => {
  subscribeModalVisible.value = true;
};

const handleCloseSubscribeModal = () => {
  showIntroduction.value = false;
  subscribeModalVisible.value = false;
};

const handleClickDiscard = () => {
  window.open('https://discord.com/invite/gudDBMm7zk', '_blank');
};

onMounted(() => {
  if (useShowCommunity() && globalDomain == 1) {
    showDiscordTip();
  }
});

const showDiscordTip = () => {
  const instance = getCurrentInstance();
  const container = instance!.vnode.el as HTMLElement;
  const tipTeleportTarget = document.getElementById('workspaceSide')!;
  const tipStyle = ref('');
  const calcPosition = () => {
    const containerOffsetTop = container.getBoundingClientRect().top;
    const targetOffsetTop = tipMountedRef.value.getBoundingClientRect().top;
    tipStyle.value = `top: ${targetOffsetTop - containerOffsetTop - 138}px`;
  };

  const handleClose = () => {
    container.removeEventListener('scroll', calcPosition);
    window.removeEventListener('resize', calcPosition);
    setItem('isShowCommunityTip', true);
    render(null, tipTeleportTarget);
  };

  const discordTipNode = createVNode(DiscordTip, {
    [scopeId]: '',
    class: 'discord-tip',
    rootStyle: createRef(tipStyle, 'value'),
    onClose: handleClose,
    onConfirm() {
      handleClose();
      handleClickDiscard();
    },
  });

  render(discordTipNode, tipTeleportTarget);
  calcPosition();

  container.addEventListener('scroll', calcPosition);
  window.addEventListener('resize', calcPosition);
};

const similarVideoMenu = ['Create Video', 'My Space', 'Pricing'];
// 菜单栏
const routeList = computed(() => {
  if (globalDomain == 2) {
    return routes.filter((route) => {
      if (similarVideoMenu.includes(route?.meta?.title)) {
        return route?.meta?.isMenu;
      }
    });
  }

  return routes.filter((route) => {
    return route?.meta?.isMenu;
  });
});

// 跳转
const handleChangePath = (item: RouteRecordRaw) => {
  if (item.name === 'Pricing') {
    gtag('event', 'click_pricing');
    handleUpgrade();
  } else {
    currentPath.value = item.path;
    router.push(item.path);
  }
};

router.afterEach((to) => {
  currentPath.value = to.path;
});

// 跳转Premium 订阅
const handleToPremium = () => {
  openPremiumInTab();
};

const handlePlayGround = () => {
  collectData('boolvideo_page_click', {
    click: 'playground',
  });
  track('boolvideo_page_click');
  window.open('/play-ground', '_blank');
};

onBeforeUnmount(() => {
  if (cancelTimer.value) {
    clearInterval(cancelTimer.value);
  }
});
</script>

<template>
  <div
    class="side-bar h-full w-sideBar xl:w-largeSideBar sidebar-border duration-150 relative"
  >
    <div>
      <ul class="px-3 py-5">
        <li
          v-for="item in routeList"
          :key="item.path"
          class="pl-6 w-full cursor-pointer text-navTextColor h-10 mb-2 rounded flex items-center duration-300"
          :class="{ 'bg-navActiveColor': currentPath == item.path }"
          @click="handleChangePath(item)"
        >
          <img
            :src="item.meta?.icon as string"
            v-if="item.meta && item.meta.icon"
            class="mr-3.5"
          />
          <p>{{ item.name }}</p>
        </li>
      </ul>
      <bv-guide
        name="cancel-promotion-guide"
        title="🎉 Welcome back"
        description="We've missed you. Hurry, this offer is away! Use this code to get 30% OFF for any plan"
        confirm-text="Got it"
        placement="right"
        :visible="cancelGuideVisible && cancelOnTop && globalDomain == 1"
        v-if="cancelGuideVisible"
      >
        <template #reference>
          <div class="cancel-countdown-container mr-3 ml-3">
            <p class="promotion-countdown">{{ cancelCountDown }}</p>
            <p>Get 30%OFF use code:</p>
            <p>WELCOMEBACK</p>
          </div>
        </template>
      </bv-guide>
    </div>
    
    <div class="px-3 pb-9">
      <!-- <div class="playground-container" v-if="globalDomain == 1">
        <div class="playground-item" @click="handlePlayGround">
          <div class="flex items-center">
            <svg-icon
              name="icon_playground"
              width="26.73"
              height="26.73"
            ></svg-icon>
            <span class="ml-2 title">Playground</span>
            <svg-icon name="icon_right_arrow" width="18" height="18"></svg-icon>
          </div>
          <div class="describe">
            New AI-powered tools turn imagination into innovation
          </div>
        </div>
      </div> -->
      <div
        v-if="globalDomain == 1"
        class="discord-container"
        @click="handleClickDiscard"
        ref="tipMountedRef"
      >
        <div class="discord-item">
          <div class="flex items-center">
            <svg-icon name="icon_discord" width="18" height="18"></svg-icon>
            <span class="title">Boolvideo Community</span>
          </div>
        </div>
      </div>
      <side-info />

      <subscribe-dialog
        :visible="subscribeModalVisible"
        @close="handleCloseSubscribeModal"
        :showIntroduction="showIntroduction"
      />
    </div>
  </div>
</template>

<style lang="scss">
.el-aside {
  overflow: inherit;
}
</style>

<style scoped lang="scss">
.sidebar-border {
  border-right: 1px solid #e8e9ec;

  :deep(.el-descriptions__label) {
    margin-right: 0;
  }
}

.discord-tip {
  position: absolute;
  left: calc(100% + 26px);
  z-index: 10;
  top: -138px;
}

.playground-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.side-bar {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.playground-item {
  width: 100%;
  height: 100px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  padding: 12px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .title {
    color: #262626;
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-right: 2px;
  }

  .describe {
    color: #646a73;
    font-family: Inter-variant;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &:hover {
    background: #f8f9fd;
  }
}

.discord-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 13px;
  margin-bottom: 23px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: #fff;
  cursor: pointer;
  margin-top: 18px;

  &:hover {
    background: #f8f9fd;
  }

  .discord-item {
    color: #262626;
    /* Body/Medium-14px */
    font-family: Inter-variant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    /* 157.143% */
    .title {
      margin-left: 6px;
    }
  }
}

.discord-tip {
  position: absolute;
  top: 0;
}

.cancel-countdown-container {
  padding: 12px 21px;
  border-radius: 4px;
  border: 0.5px solid #ede5ff;
  background: #f8f5ff;

  & > p {
    color: #060606;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    user-select: text;
  }

  & > .promotion-countdown {
    color: #6741ff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>
