<template>
  <el-dialog
    class="payment-polling-modal"
    align-center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :model-value="modelValue"
    @close="$emit('update:modelValue', false)"
  >
    <template #header>
      <div class="header-container">
        <span>Payment</span>
        <icon-button
          v-if="!querying"
          name="icon_close"
          color="#646A73"
          :size="20"
          @click="$emit('update:modelValue', false)"
        />
      </div>
    </template>
    <div class="content-container">
      <template v-if="querying">
        <svg-icon 
          name="icon_loading" 
          class="animate-spin duration-300"
          :size="48"
        />
        <p class="description">Payment Underway, Thank You for Your Patience</p>
      </template>
      <template v-else-if="errorMsg">
        <svg-icon 
          name="icon_error" 
          :size="48"
        />
        <p class="description">{{ errorMsg }}</p>
      </template>
      <template v-else>
        <svg-icon 
          name="icon_payment_success" 
          :size="48"
        />
        <p class="description">Payment successful! Thank you for your purchase.</p>
      </template>
    </div>
  </el-dialog>
</template>

<script setup>
import { createSubscription as bCreateSubscription } from "@/api/premium";
import { createSubscription as sCreateSubscription } from "@/api/svPremium";
import { paymentPolling } from "@/utils/premium";
import { parseUrlParams } from "@/utils/url";
import { getItem } from "@/utils/storage";
import { useTrackStore } from "@/store/modules/track";
import { useSubscriptionInfo } from "@/store/modules/user";

const props = defineProps({
  modelValue: Boolean,
});
const emit = defineEmits([
  "update:modelValue"
]);

const { updateSubscriptionState, refresh } = useSubscriptionInfo();
const { track } = useTrackStore();
const router = useRouter();

const setupIntentId = ref("");
const customerId = ref("");
const packageCode = ref(0);
const couponCode = ref(0);
const orderId = ref(-1);
const errorMsg = ref("");
const querying = ref(true);

onMounted(async () => {
  const { coupon_code, customer_id, package_code, setup_intent } = parseUrlParams(window.location.search);
  couponCode.value = coupon_code === "null" ? null: coupon_code;
  setupIntentId.value = setup_intent;
  packageCode.value = package_code;
  customerId.value = customer_id;

  router.replace({query: {}});

  await create();

  if (orderId.value === - 1) return;
  else {
    await paymentPolling(orderId.value)
      .then(async (orderData) => {
        track("boolvideo_subscribe_confirm");
        gtag("event", `subscribe_success`, {
          transaction_id: orderData?.orderNo,
          package_code: orderData?.packageCode,
          value: orderData?.payAmount ?  orderData.payAmount / 100 : 0 ,
          currency: 'USD'
        });
        refresh();
        updateSubscriptionState();
      })
      .catch(e => {
        const { state } = error;
        if (state === "PAYMENT_ERROR") {

        }
        errorMsg.value = "Please check your credit card limit and availability.";
      })
      .finally(() => {
        querying.value = false;
      });
  }

});

const create = async () => {
  const globalDomain = getItem("currentDomain");
  const createSubscription = async (params) => {
    switch(globalDomain) {
      case 1:
        return await bCreateSubscription(params);
      case 2:
        return await sCreateSubscription(params);
    }
  };
  const { data, success, msg } = await createSubscription({
    couponCode: couponCode.value,
    customerId: customerId.value,
    packageCode: packageCode.value,
    setupIntentId: setupIntentId.value,
    toltReferral: window.tolt_referral
  });
  if (!success) {
    orderId.value = -1;
    querying.value = false;
    errorMsg.value = msg;
  }
  else {
    orderId.value = data.orderId;
  }
};
</script>

<style lang="scss" scoped>
.payment-polling-modal {
  .header-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 28px;
    color: #060606;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .content-container {
    height: 680px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > .description {
      margin-top: 20px;
      color: #060606;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }
  }

  @media screen and (min-width: 2600px) and (max-width: 3000px) {
    .content-container {
      height: 1000px;
    }
  }

  @media screen and (min-width: 3000px) and (max-width: 4000px) {
    .content-container {
      height: 1200px;
    }
  }


  @media screen and (min-width: 4000px) {
    .content-container {
      height: 1500px;
    }
  }


  .scroll-container {
    padding: 0px 20px;
    overflow: auto;
    height: 45vh;
  }
}
</style>

<style lang="scss">
.payment-polling-modal {
  &.el-dialog {
    min-width: 507px;
    // max-height: 1200px;
    border-radius: 4px;

    & > .el-dialog__header {
      padding: 22px 28px;
      margin: 0;
      border-bottom: 1px solid #e5e7eb;
    }

    & > .el-dialog__body {
      padding: 0px 0px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    & > .el-dialog__footer {
      padding: 20px;
    }
  }
}
</style>