<template>
  <router-view></router-view>
  <user-portrait v-if="showUserPortrait"></user-portrait>
  <payment-polling v-if="showPaymentPolling" v-model="showPaymentPolling" />
</template>

<script setup>
import { getItem } from "@/utils/storage";
import { isLogin } from "@/utils/auth";
import { parseUrlParams } from "@/utils/url";
import { identifyUser, setTrackApp } from "./utils/track";
import { useSubscriptionInfo } from "@/store/modules/user";
import UserPortrait from "@/components/user-portrait/index.vue"
import PaymentPolling from "@/components/payment-polling-modal/index.vue";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import md5 from "md5";

const subscriptionInfo = useSubscriptionInfo();
const router = useRouter();

const { VITE_TRACK_APP_ID: appId } = import.meta.env;
const showUserPortrait = computed(() => {
  const excludeRoutes = ['/signup', '/login', '/resetPwd', '/redeem/rockethub'];
  const comeFromGoogle = 'Googlesearch-';
  const currentPath = router.currentRoute.value.path;
  const source = localStorage.getItem('user_source');
  return (
    isLogin.value &&
    !excludeRoutes.includes(currentPath) &&
    !source?.startsWith(comeFromGoogle) &&
    globalDomain === 1
  );
});
const showPaymentPolling = ref(false);
const globalDomain = inject("globalDomain");

// setup track app
function setupGleap(Gleap, t, i) {
  if (!(Gleap = window.Gleap = window.Gleap || []).invoked) {
    for (
      window.GleapActions = [],
        Gleap.invoked = !0,
        Gleap.methods = [
          'identify',
          'setEnvironment',
          'setTags',
          'attachCustomData',
          'setCustomData',
          'removeCustomData',
          'clearCustomData',
          'registerCustomAction',
          'trackEvent',
          'log',
          'preFillForm',
          'showSurvey',
          'sendSilentCrashReport',
          'startFeedbackFlow',
          'startBot',
          'setAppBuildNumber',
          'setAppVersionCode',
          'setApiUrl',
          'setFrameUrl',
          'isOpened',
          'open',
          'close',
          'on',
          'setLanguage',
          'setOfflineMode',
          'initialize',
          'disableConsoleLogOverwrite',
          'logEvent',
          'hide',
          'enableShortcuts',
          'showFeedbackButton',
          'destroy',
          'getIdentity',
          'isUserIdentified',
          'clearIdentity',
          'openConversations',
          'openConversation',
          'openHelpCenterCollection',
          'openHelpCenterArticle',
          'openHelpCenter',
          'searchHelpCenter',
          'openNewsArticle',
          'openChecklists',
          'startChecklist',
          'openNews',
          'openFeatureRequests',
          'isLiveMode',
        ],
        Gleap.f = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            window.GleapActions.push({ e: e, a: t });
          };
        },
        t = 0;
      t < Gleap.methods.length;
      t++
    )
      Gleap[(i = Gleap.methods[t])] = Gleap.f(i);
    (Gleap.load = function () {
      var t = document.getElementsByTagName('head')[0],
        i = document.createElement('script');
      (i.type = 'text/javascript'),
        (i.async = !0),
        (i.src = 'https://sdk.gleap.io/latest/index.js'),
        t.appendChild(i);
    }),
      Gleap.load(),
      Gleap.initialize(appId);
  }

  Gleap.on('initialized', () => {
    setTrackApp(window.Gleap);

    if (getItem('user_email')) {
      identifyUser(getItem('user_email'), {
        email: getItem('user_email'),
        username: getItem('user_name'),
      });
    }
  });
}
globalDomain === 1 && setupGleap();
const createFingerprint = () => {
  // 浏览器指纹
  const fpPromise = FingerprintJS.load();
  (async () => {
    const fp = await fpPromise;
    const result = await fp.get();
    const browserId = result.visitorId;
    localStorage.setItem('browserId', browserId);
  })();
};

const createSessionId = () => {
  const isHaveSessionId = sessionStorage.getItem('sessionId');
  //判断当前sessionStorage中是否有sessionId 没有则创建保存，有也跳过
  if (isHaveSessionId) {
    return;
  } else {
    const sessionId = md5(Date.now());
    sessionStorage.setItem('sessionId', sessionId);
    return;
  }
};

const recordSource = () => {
  const currentRoute = router.currentRoute.value;
  const sourceKeys = ['from', 'ref', 'utm_source', 'via'];
  sourceKeys.forEach((item) => {
    if (currentRoute.query[item]) {
      localStorage.setItem('user_source', currentRoute.query[item]);
    }
  });
};

watchEffect(() => {
  recordSource();
});


onMounted(async () => {
 
  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      createFingerprint();
    });
  } else {
    setTimeout(() => {
      createFingerprint();
    }, 500);
  }

  await subscriptionInfo.refresh();
  createSessionId();
  // 检查是否三方支付重定向
  const { redirect_status } = parseUrlParams(window.location.search);
  if (redirect_status === "succeeded") {
    showPaymentPolling.value = true;
  }
});
</script>
