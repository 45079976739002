import type { RouteLocationNormalized} from "vue-router";
import { useStyle } from "@/utils/dom";
import { headMap, head } from './head';

let prevPageAuthState = false;
let headEntries: any[] = [];
const styleRule = useStyle([], false);
const gleapContainerStyle = styleRule.insertStyle('body.gleap-b-shown', {});
const gleapStyle = styleRule.insertStyle('body.gleap-b-shown > .gleap-b', {
  width: '100%',
});

const styleMap: Record<string, Record<string, Partial<CSSStyleDeclaration>>> = {
  '$default': {
    banner: {
      display: '',
      position: 'fixed',
      top: '0',
      zIndex: '10',
    },
    container: {
      marginTop: '',
    }
  },
  '$auth': {
    banner: {
      display: 'none',
    },
    container: {
      marginTop: '0',
    }
  },
  ...((style: Record<'banner' | 'container', Partial<CSSStyleDeclaration>>) => Object.fromEntries([
    'Pricing',
  ].map(key => [key, style])))({
    banner: {
      display: '',
      position: '',
      top: '',
      zIndex: '-1',
    },
    container: {
      marginTop: '',
    },
  }),
};

export function processGleapBanner(route: RouteLocationNormalized) {
  let style = styleMap['$default'];

  if (route.meta.isAuth) {
    style = styleMap['$auth'];
  } else if (typeof route.name === 'string' && route.name in styleMap) {
    style = styleMap[route.name];
  }

  Object.assign(gleapStyle, style.banner);
  Object.assign(gleapContainerStyle, style.container);
};

const refreshHeadEntries = (match: any, isAuth: boolean) => {
  const entries = [];

  for (const entry of headEntries) {
    if (entry.match === '*') {
      entries.push(entry);
    } else if (entry.match === '$auth') {
      if (isAuth) {
        entries.push(entry);
      } else {
        entry.target.dispose();
      }
    } else if (entry.match === match) {
      entries.push(entry);
    } else {
      entry.target.dispose();
    }
  }

  headEntries = entries;
}

const otherNoIndexPageSet = new Set(['ResetPwd']);
export function processHead(route: RouteLocationNormalized) {
  refreshHeadEntries(route.name, route.meta.isAuth);

  if (route.meta.isAuth || otherNoIndexPageSet.has(route.name as string)) {
    !prevPageAuthState && headEntries.push({
      match: '$auth',
      target: head.push({
        title: route.meta.title || 'Boolvideo',
        meta: [
          {name: 'robots', content: 'noindex'},
          {name: 'googlebot', content: 'noindex'},
          {name: 'bingbot', content: 'noindex'},
        ],
      }),
    });

    prevPageAuthState = true;
  } else {
    if (route.name! in headMap) {
      console.log('route.name', route.name)
      const config = headMap[route.name as string];
      const usedConfig = {...config};
      
      usedConfig.link ??= [...(usedConfig.link ?? [])];
      usedConfig.link.push({
        rel: 'canonical',
        href: location.origin + (route.path === '/' ? '' : route.path),
      });
      
      headEntries.push({
        match: route.name,
        target: head.push(usedConfig),
      });
    } else {
      headEntries.push({
        match: route.name,
        target: head.push({title: route.meta.title || 'Boolvideo'}),
      });

      prevPageAuthState = false;
    }
  }
}