<template>
  <el-dialog
    class="pricing-giftcard-modal"
    :model-value="modelValue"
    align-center
    :width="modalWidth"
    :show-close="false"
    :close-on-click-modal="false"
    @close="close"
  >
    <template #header>
      <div class="header-container">
        <icon-button
          name="icon_close"
          :size="20"
          @click="close"
        />
      </div>
    </template>
    <div class="content-container">
      <el-scrollbar>
        <div class="gift-card-wrapper" v-if="canDiscount">
        <div class="gift-card-container">
          <p class="title" style="margin-bottom: 74px;">Stay and get 50% OFF. We don't want to lose you 😭</p>

          <div class="card-info-container">
            <div class="card-info-header">
              <p class="package-name">Subscribing plan: {{ subscriptionState.currentPlanName }}</p>
              <svg-icon name="pig_bank" :size="44"/>
            </div>

            <div class="card-info-body">
              <p><span class="current-price">{{ newPrice }}</span> / next {{ cycle }}</p>
              <p>Normally <span class="previous-price">{{ oldPrice }}</span> / {{ cycle }}</p>

              <div class="discount-tag" style="margin-top: 18px;">
                <svg-icon name="card-success" style="margin-right: 4px;" :size="24"/>
                <span>Get 50% off</span>
              </div>
              <div class="discount-tag" style="margin-top: 12px">
                <svg-icon name="card-success" style="margin-right: 4px;" :size="24"/>
                <span>Saved {{ priceDiff  }} for one {{ cycle }} </span>
              </div>
            </div>
          </div>

          <!-- <div class="card-slider-container" style="margin-top: 30px;">
            <bv-slider height="10" :showTooltip="false"></bv-slider>
          </div> -->
          <div class="buttons-container" style="margin-top: 106px;">
            <CommonButton
              size="small"
              style="width:186px"
              @click="claim"
            >
              🎁 Claim offer
            </CommonButton>
            <CommonButton
              size="small"
              color="#FF5449"
              fontColor="#FFF"
              style="width:186px;"
              @click="cancel"
            >
              Cancel plan
            </CommonButton>

          </div>
        </div>
        </div>
      </el-scrollbar>
    </div>
  </el-dialog>

  <cancelSuccessModal v-model="cancelSuccessVisible" @close="closeCancelSuccess"/>
</template>

<script setup lang="jsx">
import { useModalManager } from "@/components/common/custom-modal/instance";
import cancelSuccessModal from "./cancelsuccess.vue";
import {
  modal_banner,
  Success,
} from "@/assets/images/premium";
import { 
  queryCancelDetails, 
  grantCancelDiscount,
  cancelSubscription,
} from "@/api/premium";
import { useSubscriptionInfo } from "@/store/modules/user";
import { useTrackStore } from "@/store/modules/track";

const modalManager = useModalManager();
const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { collectData, track, getTrackPlanName } = useTrackStore();

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
});
const emits = defineEmits(["update:modelValue"]);

const modalWidth = ref(1060);
const canDiscount = ref(false);
const oldPrice = ref("");
const newPrice = ref("");
const cycle = ref("month");
const claiming = ref(false);
const cancelling = ref(false);
const cancelSuccessVisible = ref(false);
const priceDiff = computed(() => {
  const p1 = oldPrice.value.split("$")[1] || 0;
  const p2 = newPrice.value.split("$")[1] || 0;

  return `$${(p1 - p2).toFixed(2)}`;
});

const computedModalWidth = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth > 4000) {
    modalWidth.value = 2500;
  }else if (screenWidth > 3000 && screenWidth < 4000) {
    modalWidth.value = 2000;
  } else if (screenWidth > 2400 && screenWidth < 3000) {
    modalWidth.value = 1500;
  } else {
    modalWidth.value = 1060;
  }
};

onMounted(() => {
  computedModalWidth();
  window.onresize = () => {
    computedModalWidth();
  };
});

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      setup();
    }
  }
);

const setup = () => {
  queryCancelDetails().then(res => {
    const { 
      availableDiscount,
      discountedPrice,
      normallyPrice,
      discountedSubscriptionCycle, 
    } = res.data;
    canDiscount.value = availableDiscount;
    if (!canDiscount.value) {
      cancel();
    }
    else {
      newPrice.value = discountedPrice;
      oldPrice.value = normallyPrice;
      cycle.value = discountedSubscriptionCycle.toLowerCase();
    }
  });
};

const claim = async () => {
  if (claiming.value) return;

  collectData("boolvideo_subscribe_cancel", {
    click: "discount",
    plan_name: getTrackPlanName(subscriptionState.value.packageCode),
  });
  track("boolvideo_subscribe_cancel");
  
  claiming.value = true;
  const { code }  = await grantCancelDiscount();
  if (code === 0) {
    claimSuccessModal();
    close();
  }
  claiming.value = false;
};

const cancel = async () => {
  if (cancelling.value) return;
  collectData("boolvideo_subscribe_cancel", {
    click: "cancel_confirm",
    plan_name: getTrackPlanName(subscriptionState.value.packageCode),
  });

  cancelling.value = true;
  const { code } = await cancelSubscription();
  if (code === 0) {
    track("boolvideo_subscribe_cancel_confirm");
    track("boolvideo_subscribe_cancel");
    cancelSuccessVisible.value = true;
  }
  cancelling.value = false;
};

const close = () => {
  emits("close")
};

const closeCancelSuccess = () => {
  cancelSuccessVisible.value = false;
  close();
}

const claimSuccessModal = () => {
  modalManager.applyTemplate("info", {
    title: "",
    content: (
      <>
        <div class="relative">
          <img class="w-full h-full" src={modal_banner} />
          <div class="flex items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img class="mr-2" src={Success} />
            <p class="text-white text-xl whitespace-nowrap">
              Your discount has been applied!
            </p>
          </div>
        </div>
        <div class="absolute z-10 top-5 right-5">
          <div
            class="p-0.5 w-fit h-fit cursor-pointer rounded-sm hover:bg-closeBtnHoverColor/50"
            onClick={() => modalManager.modal.onClose()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 8.82149L16.4316 2.39023C16.5943 2.22751 16.8582 2.22751 17.0209 2.39023L17.6101 2.97948C17.7729 3.1422 17.7729 3.40602 17.6101 3.56874L11.1789 10L17.6101 16.4313C17.7729 16.594 17.7729 16.8578 17.6101 17.0205L17.0209 17.6098C16.8582 17.7725 16.5943 17.7725 16.4316 17.6098L10.0004 11.1785L3.5691 17.6098C3.40639 17.7725 3.14257 17.7725 2.97985 17.6098L2.39059 17.0205C2.22788 16.8578 2.22788 16.594 2.39059 16.4313L8.82186 10L2.39059 3.56874C2.22788 3.40602 2.22788 3.1422 2.39059 2.97948L2.97985 2.39023C3.14257 2.22751 3.40639 2.22751 3.5691 2.39023L10.0004 8.82149Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="px-8 py-9 bg-white rounded-br-lg rounded-bl-lg text-sm">
          <p>
            As our esteemed member, we are always here to provide assistance and
            support to ensure you have a pleasant experience while using our
            services.
          </p>
          <div class="w-full text-center mt-9 flex justify-center">
            <PrimaryButton
              onclick={() => modalManager.modal.onClose()}
              size="extra-small"
              color="#FF5449"
              fontColor="#FFF"
            >
              Continue my journey
            </PrimaryButton>
          </div>
        </div>
      </>
    ),
    footer: "",
    zIndex: 3000,
  });
  modalManager.modal.showClose = false;
  modalManager.modal.onClose = () => {
    modalManager.modal.open = false;
  };
};

</script>

<style lang="scss">
.pricing-giftcard-modal {
  & > .el-dialog__header {
    padding: 22px 28px;
    margin: 0;
    border-bottom: 1px solid #e5e7eb;
  }

  & > .el-dialog__body {
    padding: 0px 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  & > .el-dialog__footer {
    padding: 20px;
  }
}
</style>

<style lang="scss" scoped>

.header-container {
  display: flex;
  justify-content: flex-end;
}

.content-container {
  height: 680px;
}

@media screen and (min-width: 2600px) and (max-width: 3000px) {
  .content-container {
    height: 1000px;
  }
}

@media screen and (min-width: 3000px) and (max-width: 4000px) {
  .content-container {
    height: 1200px;
  }
}


@media screen and (min-width: 4000px) {
  .content-container {
    height: 1500px;
  }
}
.gift-card-wrapper {
  padding: 42px;
}

.gift-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .title {
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  } 
}

.card-info-container {
  width: 416px;
  height: 260px;  
  padding: 20px 30px;
  border-radius: 12px;
  background: linear-gradient(291deg, #FFCD23 1.26%, #FF3C84 100%);
}

.card-info-header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > .package-name {
    height: fit-content;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.40);
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
  }
}

.card-info-body {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  & .current-price {
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
    line-height: 46px; 
  }

  & .previous-price {
    text-decoration: line-through;
  }
}

.discount-tag {
  padding: 6px 12px;
  border-radius: 59px;
  background: #1C1B1E;
  display: flex;
  align-items: center;
  width: fit-content;

  & > span {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;  
  }
}

.card-slider-container {
  width: 416px;
}

.buttons-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

:deep(.card-slider-container .el-slider) {
  --el-slider-border-radius: 15px;
  --el-slider-height: 10px;
  --el-slider-button-size: 30px;
  --el-slider-button-wrapper-size: 30px;
  --slider-main-color: #1C1B1E!important;
  --slider-runway-color: #EBEDEF !important;
  --slider-track-color: #1C1B1E !important;
}

.modal-container {
  padding: 0;
  position: relative;
  z-index: 10;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 36%;
  max-width: 530px;
  min-width: 430px;
  border-radius: 8px;
}

.modal-header {
  width: 100%;

  p:first-child {
    width: 100%;
  }
}

.modal-footer {
  padding: 24px;
  margin-top: 0;
}

.modal-content {
  position: relative;
}

.modal-content {
  margin-top: 0;
}
</style>