<template>
  <div class="footer-container">
    <div class="button-container">
      <PrimaryButton
        size="large"
        class="next-button"
        :disabled="buttonDiabled"
        @click="handleClick"
      >
        {{ 
          page === pageList.length - 1 ?
          "Submit" : "Next"
        }}
      </PrimaryButton>
    </div>
    <div class="pagination">
      <svg-icon 
        name="icon_left"
        :size="12"
        :disabled="preDisabled"
        @click="pre()"
      />
      <span>
        {{ (page + 1) + " / " + pageList.length}}
      </span>
      <svg-icon 
        name="icon_left"
        style="transform: rotate(180deg);"
        :size="12"
        :disabled="nextDisabled"
        @click="next()"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  page: {
    type: Number,
    default: 0,
  },
  pageList: {
    type: Array,
    default: [],
  },
});
const emits = defineEmits(["updatePage", "comfirm"]);

const currentQuestion = computed(() => {
  return props.pageList[props.page];
});
const buttonDiabled = computed(() => {
  return !currentQuestion.value.values.some((item) => item.selected);
});
const preDisabled = computed(() => {
  const prePage = props.page - 1;
  const prePageVisited = props.pageList[prePage]?.visited;
  return props.page === 0 || !prePageVisited;
});
const nextDisabled = computed(() => {
  const lastPage = props.pageList.length - 1;
  const nextPage = props.page + 1;
  const nextPageVisited = props.pageList[nextPage]?.visited;
  return props.page === lastPage || !nextPageVisited;
});

const pre = () => {
  if (preDisabled.value) {
    return ;
  }
  emits("updatePage", props.page - 1);
};

const next = () => {
  if (nextDisabled.value) {
    return ;
  }
  emits("updatePage", props.page + 1);
};

const handleClick = () => {
  if (buttonDiabled.value) {
    return ;
  }
  const lastPage = props.pageList.length - 1;
  if (props.page === lastPage) {
    emits("submit");
  }
  else {
    emits("updatePage", props.page + 1);
  }
};
</script>

<style lang="scss" scoped>
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  width: 298px;
  margin-bottom: 41px;
}

:deep(.next-button .el-button){
  width: 100%;
}

.progress-container {
  width: 950px;
  margin-bottom: 24px;
}

:deep(.progress-container .el-progress-bar__outer) {
  height: 2px !important;
  border-radius: 0;
  background-color: #E5E7EB;
}

:deep(.progress-container .el-progress-bar__inner) {
  border-radius: 0;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  
  & span {
    padding: 0 16px;
    text-align: center;
    color: #060606;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-variant-numeric: tabular-nums;
  }
}

.pagination svg {
  cursor: pointer;
  color: #1C1B1E;

  &.disabled {
    color: #8F959E;
  }
}

</style>