import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { isLogin } from "@/utils/auth";
import { authenticator } from "@/utils/authenticate";
import { showTrackApp } from '@/utils/track';
import { processGleapBanner, processHead } from "./helper";
import { getItem } from "@/utils/storage";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  try {
    processHead(to);
    processGleapBanner(to);
  } catch (e) {
    console.error(e);
  }
  
  const globalDomain = getItem("currentDomain");
  if (globalDomain === 2) {
    const similarVideoBanMap = ["/", "/premium"];
    if (similarVideoBanMap.includes(to.path)) {
      next({
        path: "/workspace",
        query: {
          ...to.query
        },
      });
      return;
    }
  }

  if (to.meta.isAuth) {
    if (isLogin.value) {
      next();
    } else {
      localStorage.setItem("preRoute", to.fullPath);
      next({
        path: "/signup",
        query: {
          redirect: to.fullPath,
          ...to.query,
        },
      });

      return;
    }
  } else {  
    next();
  }
  
  authenticator.refresh();
});

router.afterEach((to, from) => {
  if (to.name === 'Editor') {
    showTrackApp(false);
  } else {
    showTrackApp(true);
  }
});

export default router;
