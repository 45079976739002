import { defineStore } from "pinia";
import { getEmail, isLogin } from "@/utils/auth";
import { useSubscriptionInfo } from "@/store/modules/user";
import { reportData } from "@/api/track";
import { trackEvent as trackEventToPlatform } from "@/utils/track";
import md5 from "md5";
import { getItem } from "@/utils/storage";

export const useTrackStore = defineStore("track", () => {
  const initialTrackEventData = () => ({
    boolvideo_homepage_view: {
      eventName: "boolvideo_homepage_view",
    },
    boolvideo_pricing_view: {
      eventName: "boolvideo_pricing_view",
    },
    boolvideo_signup_view: {
      eventName: "boolvideo_signup_view",
    },
    boolvideo_signup_success: {
      eventName: "boolvideo_signup_success",
    },
    boolvideo_page_view: {
      eventName: "boolvideo_page_view",
    },
    boolvideo_page_click: {
      eventName: "boolvideo_page_click",
    },
    boolvideo_create_upload: {
      eventName: "boolvideo_create_upload",
    },
    boolvideo_create_input: {
      eventName: "boolvideo_create_input",
      video_purpose: "",
      video_subtype: "",
    },
    boolvideo_create_parameter_input: {
      eventName: "boolvideo_create_parameter_input",
      video_purpose: "",
      ratio: "",
      randomness: "",
    },
    boolvideo_edit_click: {
      eventName: "boolvideo_edit_click",
      video_type: "",
      template_id: 0,
    },
    boolvideo_export_click: {
      eventName: "boolvideo_export_click",
      video_type: "",
      editor_type: "",
      ratio: "",
      template_id: 0,
      script_id: 0,
      formatting_id: 0,
      music_id: [],
      filter_id: [],
      sticker_id: [],
    },
    boolvideo_template_tag_click: {
      eventName: "boolvideo_template_tag_click",
      tag_name: "",
    },
    boolvidero_template_ratio_click: {
      eventName: "boolvidero_template_ratio_click",
      ratio: "",
    },
    boolvideo_template_click: {
      eventName: "boolvideo_template_click",
      template_id: "",
    },
    boolvideo_template_upload: {
      eventName: "boolvideo_template_upload",
    },
    boolvideo_template_input: {
      eventName: "boolvideo_template_input",
      product_name: "",
      product_detail: "",
      template_id: 0,
    },
    boolvideo_space_click: {
      eventName: "boolvideo_space_click",
      click: "",
      view_title: "",
    },
    boolvideo_cta_view: {
      eventName: "boolvideo_cta_view",
      view_title: "",
    },
    boolvideo_cta_click: {
      eventName: "boolvideo_cta_click",
      view_title: "",
    },
    boolvideo_subscribe_click: {
      eventName: "boolvideo_subscribe_click",
      click: "",
      plan_name: "",
    },
    boolvideo_subscribe_cancel: {
      eventName: "boolvideo_subscribe_cancel",
      click: "",
      plan_name: "",
    },
    boolvideo_subscribe_change: {
      eventName: "boolvideo_subscribe_change",
      click: "",
      plan_name: "",
    },
    boolvideo_deal_page_view: {
      eventName: "boolvideo_deal_page_view",
    },
    boolvideo_deal_subscribe_click: {
      eventName: "boolvideo_deal_subscribe_click",
      click: "",
      plan_name: "",
    },
    boolvideo_editor_page_view: {
      eventName: "boolvideo_editor_page_view",
    },
    boolvideo_aitools_use: {
      eventName: "boolvideo_aitools_use",
      tool_name: "",
      access: "",
    },
    boolvideo_brandkit_click: {
      eventName: "boolvideo_brandkit_click",
      click: "",
    },
    boolvideo_text_input: {
      eventName: "boolvideo_text_input",
      idea_prompt: undefined,
      video_type: "",
      duration: "",
      ratio: "",
      language: "",
    },
    boolvideo_scene_edit_click: {
      eventName: "boolvideo_scene_edit_click",
      click: "",
      video_type: "",
      replace_type: undefined,
    },
    boolvideo_scene_translate_click: {
      eventName: "boolvideo_scene_translate_click",
      video_type: "",
      language: "",
    },
    boolvideo_subscribe_confirm: {
      eventName: "boolvideo_subscribe_confirm",
      plan_name: "",
    },
    boolvideo_subscribe_cancel_confirm: {
      eventName: "boolvideo_subscribe_cancel_confirm",
      plan_name: "",
    },
    boolvideo_subscribe_upgrade_confirm: {
      eventName: "boolvideo_subscribe_upgrade_confirm",
      plan_name: "",
    },
    boolvideo_subscribe_downgrade_confirm: {
      eventName: "boolvideo_subscribe_downgrade_confirm",
      plan_name: "",
    },
    boolvideo_save_to_draft: {
      eventName: "boolvideo_save_to_draft",
      video_type: "",
    },
    boolvideo_ai_assistant_use: {
      eventName: "boolvideo_ai_assistant_use",
      click: "",
    },
    boolvideo_timeline_edit_click: {
      eventName: "boolvideo_timeline_edit_click",
      element_type: undefined,
      click: "",
      element_id: undefined,
      text_preset: undefined,
      with_mask: undefined,
      resize_ratio: undefined,
    },
    boolvideo_upload_media: {
      eventName: "boolvideo_upload_media",
      media_type: "",
      is_batch_upload: false,
      access: "",
    },
    
    similarvideo_similar_input: {
      eventName: "similarvideo_similar_input",
      video_url: "",
      product_name: "",
      product_description: "",
    },

    boolvideo_upload_project_media: {
      eventName: "boolvideo_upload_project_media",
      media_type: "",
      name: '',
      size: '',
    },

    similarvideo_upload_project_media: {
      eventName: "boolvideo_upload_project_media",
      media_type: "",
      name: '',
      size: '',
    },

    similarvideo_script_input: {
      eventName: "similarvideo_script_input",
      clone_script: "",
      clone_ratio: "",
    },
    similarvideo_similar_script_edit: {
      eventName: "similarvideo_similar_script_edit",
      click: "",
      original_script: "",
      chosen_script: "",
    },
    similarvideo_similar_celebrity_click: {
      eventName: "similarvideo_similar_celebrity_click",
      name: "",
      id: "",
    },
    similarvideo_similar_generate: {
      eventName: "similarvideo_similar_generate",
      video_style: "",
      celebrity: "",
      celebrity_fragment: "",
    },
    similarvideo_script_celebrity_click: {
      eventName: "similarvideo_script_celebrity_click",
      name: "",
      id: "",
    },
    similarvideo_script_generate: {
      eventName: " similarvideo_script_generate",
      video_style: "",
      celebrity: "",
      celebrity_fragment: "",
    },
  });
  // 需要缓存的埋点事件
  const cacheEventDataList = [
    "boolvideo_export_click",
    "boolvideo_template_input",
    "boolvideo_edit_click",
    "boolvideo_subscribe_confirm",
    "boolvideo_subscribe_upgrade_confirm",
    "boolvideo_subscribe_downgrade_confirm",
    "boolvideo_subscribe_cancel_confirm",
  ];
  // 被缓存的埋点数据
  const eventCollector: EventName[] = reactive([]);
  const trackEvent = reactive(initialTrackEventData());

  type EventName = keyof typeof trackEvent;
  type EventData = ValueOf<typeof trackEvent>;

  const getTrackPlanName = (packageCode: number) => {
    if (packageCode === undefined) return "none";

    const planMap = {
      1: "contact us",
      300101: "free",
      300201: "standard",
      300301: "pro",
      300401: "starter",
      300202: "standard_annual",
      300302: "pro_annual",
      300402: "starter_annual",
      300204: "standard_ltd",
      300304: "pro_ltd",
      300404: "starter_ltd",
      300205: "rh_standard",
      300305: "rh_pro",
      300405: "rh_starter",
    };
    // @ts-ignore
    return planMap[packageCode] as string;
  };

  const getEventData = (eventName: EventName) => {
    const needToStore = cacheEventDataList.includes(eventName);
    if (needToStore) {
      const cacheData = getCacheData();
      Object.assign(trackEvent[eventName], cacheData[eventName]);
    }
    return trackEvent[eventName];
  };

  // 缓存数据
  const getCacheData = () => {
    const cacheData = sessionStorage.getItem("cacheEventData");
    if (cacheData) return JSON.parse(cacheData);
    else return {};
  };
  const storeCacheData = (eventData: EventData) => {
    const cacheData = getCacheData();
    Object.assign(cacheData, {
      [eventData.eventName]: eventData,
    });
    sessionStorage.setItem("cacheEventData", JSON.stringify(cacheData));
  };

  //收集数据
  const collectData = (eventName: EventName, property: object) => {
    
    const needToStore = cacheEventDataList.includes(eventName);
    if (needToStore) {
      const cacheData = getCacheData();
      Object.assign(trackEvent[eventName], cacheData[eventName]);
    }
    Object.assign(trackEvent[eventName], property);
    if (needToStore) {
      storeCacheData(trackEvent[eventName]);
    }
  };

  // 重置数据
  const clearEventData = (eventName: EventName) => {
    const needToStore = cacheEventDataList.includes(eventName);
    if (needToStore) {
      storeCacheData(initialTrackEventData()[eventName]);
    }
    Object.assign(trackEvent[eventName], {
      ...initialTrackEventData()[eventName],
    });
  };

  // 上报数据
  const track = async (eventName: EventName) => {
    const globalDomain = getItem("currentDomain");

    const deviceId = localStorage.getItem("browserId");
    if (!deviceId) {
      eventCollector.push(eventName);
      return;
    } else {
      const getPayload = (eventName: EventName) => {
        const { eventName: event_name, ...properties } =
          getEventData(eventName);
        const sessionId = sessionStorage.getItem("sessionId");
        const timestamp = Date.now();
        const appId = (() => {
          switch(globalDomain){
            case 1:
              return 5;
            case 2:
              return 6;
            default: 
              return 5;
          }
        })();
        const app_name = (() => {
          switch(globalDomain){
            case 1:
              return "boolvideo";
            case 2:
              return "similarvideo";
            default: 
              return "boolvideo";
          }
        })();
        const transformEventName = (() => {
          if (globalDomain === 2) {
            return event_name.replace("boolvideo", "similarvideo");
          }
          else {
            return event_name;
          }
        })();
        const signId = md5(
          appId + deviceId + timestamp + transformEventName + "gJUyT9qGK",
        );


        const publicParams = {
          app_id: appId,
          device_id: deviceId,
          session_id: sessionId,
          app_name: app_name,
          device_type: getDeviceType(),
          session_source: getSessionsSource(),
          timestamp,
          user_id: getEmail() || "none",
          subscribed_plan: isLogin.value ? getPlanName() : "none",
          sign: signId,
        };
        const payload = {
          ...publicParams,
          event: {
            event_name: transformEventName,
            properties,
          },
        };
        return {
          payload,
          publicParams,
          properties,
        };
      };

      if (eventCollector.length) {
        eventCollector.forEach((eventName) => {
          const { payload } = getPayload(eventName);
          reportData(payload);
        });
        // 清除缓存数据
        clearEventCollector();
        return;
      }

      const { payload, publicParams, properties } = getPayload(eventName);
      reportData(payload);

      const trackedData = {
        ...publicParams,
        event_name: payload.event.event_name,
        ...properties,
      };
      
      ///@ts-ignore
      // gtag("event", payload.event.event_name, trackedData );
      trackEventToPlatform(payload.event.event_name, trackedData);
    }
  };

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const getPlanName = () => {
    const planMap = {
      300101: "free",
      400101: "free",
      300201: "standard",
      400201: "standard",
      300301: "pro",
      400301: "pro",
      300401: "starter",
      300202: "standard_annual",
      300302: "pro_annual",
      300402: "starter_annual",
      300204: "standard_ltd",
      300304: "pro_ltd",
      300404: "starter_ltd",
      300205: "rh_standard",
      300305: "rh_pro",
      300405: "rh_starter",
    };
    const subscriptionInfo = useSubscriptionInfo();
    if (subscriptionInfo.$state.packageCode <= 0) {
      return "none";
    } else {
      if (
        Object.keys(planMap).includes(subscriptionInfo.$state.packageCode + "")
      ) {
        return planMap[
          subscriptionInfo.$state.packageCode as keyof typeof planMap
        ];
      } else {
        console.log("packageCode 错误");
      }
    }
  };

  const getSessionsSource = () => {
    return localStorage.getItem("user_source") || "organic";
  };

  const clearEventCollector = () => {
    eventCollector.length = 0;
  };

  return {
    collectData,
    getEventData,
    clearEventData,
    getTrackPlanName,
    track,
  };
});
